import React from 'react'
import News from '../components/News';

const Blogs = () => {
  return (
    <>
      <News />
    </>
  )
}

export default Blogs;